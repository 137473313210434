<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-25 16:49:53
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 19:53:47
-->
<template>
  <bodyLayer>
    <div class="base">
      <div class="hd">
        <div class="left">
          <div class="item active">
            <div class="title">资料下载</div>
            <div class="child" v-if="(dcategory && dcategory.length)">
              <div class="cell" @click="tipShow(d, idx)" v-for="(d, idx) in dcategory" :key="idx">
                {{ d.name }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="bre">
            <div>当前位置：</div>
            <div>首页</div>
            <div class="gt">&gt;</div>
            <div>资料下载</div>
            <div class="gt">&gt;</div>
            <div class="last">{{obj.name}}</div>
          </div>
          <new-list :list="list" type="down"></new-list>
          <div class="pagin">
            <el-pagination background @current-change="handleCurrentChange" :current-page.sync="page" :page-size="20"
              layout="prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </div>
      </div>
      <links></links>
    </div>
  </bodyLayer>
</template>

<script>

import bodyLayer from '@/components/bodyLayer.vue';
import links from '@/components/links.vue';
import newList from '@/components/newList.vue';
import http from '@/util/http'
import { mapState } from 'vuex';
export default {
  components: { links, newList, bodyLayer },
  data() {
    return {
      page: 1,
      total: 0,
      active: 0,
      cid: 203,
      items: '',
      list: [],
      obj: {},

      keyword: '',
      page: 1
    };
  },
  computed: {
    ...mapState(['dcategory'])
  },
  mounted() {
    var index = this.$route.query.cid;
    if (index) {
      setTimeout(() => {
        this.cid = this.dcategory[index].id;
        this.obj = this.dcategory[index];
        this.getList();
      }, 200)
      return;
    }else{
      this.obj = {name: '其他'};
      this.getList();
    }
  },
  methods: {
    async getList() {
      var params = {
        keyword: this.keyword,
        c_id: this.cid,
        page: this.page,
      }
      var { data } = await http.post('/api/portal/getList.html', params);
      this.total = data.total;
      this.list = data.data;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    tipShow(item, index){
      if(this.cid == item.id) return;
      this.cid = item.id;
      this.obj = item;
      this.getList();
    }
  },
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 0 30px;
  padding-top: 40px;
  position: relative;

  .hd {
    width: 100%;
    display: flex;

    .right {
      padding-left: 30px;
      flex: 1;

      .pagin {
        padding-top: 40px;
      }


      .bre {
        height: 60px;
        line-height: 60px;
        color: #999999;
        font-size: 14px;
        display: flex;

        .last {
          font-weight: bold;
          color: #FF2F07;
        }

        .gt {
          padding: 0 10px;
        }
      }
    }

    .left {
      width: 240px;

      .item {
        width: 100%;
        max-height: 60px;
        font-size: 18px;
        overflow: hidden;
        transition: all 1s;
        margin-bottom: 10px;

        &.active {
          max-height: 1800px;
        }

        .title {
          height: 60px;
          line-height: 60px;
          cursor: pointer;
          background-color: #FF6A29;
          color: #fff;
          padding: 0 34px;
          position: relative;

          &::before {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #fff;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .child {
          .cell {
            height: 60px;
            width: 100%;
            padding: 0 34px;
            background-color: #F5F5F5;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>